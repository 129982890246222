// import { Box } from '@mui/joy'
import Image from 'next/image'
import { forwardRef } from 'react'
import { Flex } from '../../Flex'
import { Paragraph, Text } from '../../Text'

const FLEX_ALIGN = {
    left: 'flex-start',
    right: 'flex-end',
    center: 'center',
}

function VFeatureBlock(
    {
        align,
        title,
        description,
        image,
        titleContainer = {},
        contentContainer = {},
    },
    ref
) {
    return (
        <Flex ref={ref} flexDirection="column" height="100%">
            {image && (
                <Flex
                    style={{
                        justifyContent: FLEX_ALIGN[align],
                    }}
                >
                    <Image
                        quality={80}
                        src={image}
                        width={100}
                        height={100}
                        alt="Logo"
                        style={{
                            objectFit: 'cover',
                        }}
                    />
                </Flex>
            )}

            <Text
                name="title"
                pb={3}
                fontSize={{
                    md: '24px',
                    sm: '21px',
                    xs: '32px',
                }}
                fontWeight={700}
                color="#0d417d"
                textAlign={align}
                sx={{ ...titleContainer, whiteSpace: 'pre-wrap' }}
            >
                {title}
            </Text>
            <Paragraph
                fontSize={{
                    md: '16px',
                    sm: '15px',
                }}
                fontWeight={300}
                color="#0d417d"
                textAlign={align}
                // whiteSpace="pre-wrap"
                sx={contentContainer}
            >
                {description}
            </Paragraph>
        </Flex>
    )
}

export default forwardRef(VFeatureBlock)
