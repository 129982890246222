import { Box, Grid } from '@mui/joy'
import { Section } from '../../Section'
import VFeatureBlock from '../common/VFeatureBlock'
import { Heading } from '../../Text'

export default function Features({ title, blocks = [] }) {
    return (
        <Section
            pt={{
                xs: 20,
                sm: 24,
            }}
            pb={{
                xs: 20,
                sm: 32,
            }}
            backgroundColor="#e3eff6"
        >
            <Heading
                textAlign="center"
                fontWeight={700}
                color="#0d417d"
                mb={{
                    xs: 10,
                    sm: 15,
                }}
            >
                {title}
            </Heading>
            <Grid
                container
                spacing={4}
                columnSpacing={{
                    xs: 0,
                    sm: 0,
                    md: 4,
                    lg: 6,
                }}
            >
                {blocks.map((feature, index) => (
                    <Grid
                        xs={12}
                        sm={4}
                        md={4}
                        // eslint-disable-next-line react/no-array-index-key
                        key={`Feature_Block_${index}`}
                    >
                        <Box
                            height="100%"
                            p={4}
                            backgroundColor="#fff"
                            color="#0d417d"
                        >
                            <VFeatureBlock
                                align="center"
                                title={feature.title}
                                description={feature.description}
                                titleContainer={{}}
                                image={feature.image?.url}
                            />
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Section>
    )
}
